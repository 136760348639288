<template>
  <VContainer
    fluid
  >
    <VRow>
      <VCol>
        <h1 class="tt-text-headline-1">
          Аналитика по вопросам
        </h1>
      </VCol>
    </VRow>
    <VRow class="justify-space-between align-center mb-3">
      <VCol
        cols="6"
        class="py-0"
      >
        <TTSelect
          ref="locationDropDown"
          :value="currentLocationId"
          :items="locations"
          item-text="name"
          item-value="id"
          return-object
          large
          label="Локация"
          :menu-props="{ auto : true }"
          class="tt-select-attach"
          attach
          data-test="select-locations"
          @change="handleLocation"
        >
          <template #selection="{item,disabled}">
            <TextTemplate
              class="v-select__selection v-select__selection--comma"
              :disabled="disabled"
              :text="item.name"
              :show-prefix="!item.is_active && !!item.id"
            />
          </template>
          <template #item="{item}">
            <TextTemplate
              :text="item.name"
              :show-prefix="!item.is_active && !!item.id"
            />
          </template>
        </TTSelect>
      </VCol>
    </VRow>
    <VRow v-show="currentLocationId && contentAnalyticsList.length">
      <VCol>
        <TTDataTable
          :items="contentAnalyticsList"
          :headers="headers"
          extra-tall
          hide-default-footer
          :server-items-length="contentAnalyticsList.length"
          data-test="location-table"
        >
          <template #item.element=" { item } ">
            <RouterLink
              tag="div"
              :to="{ name : Names.R_REPORT_CONTENT_DETAIL, params : { id : item.id } }"
              class="text-decoration-none cursor--pointer"
            >
              <div class="ellipsis-four-lines">
                <TTTooltip top>
                  <template #activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.metaJSON.question || '' }}
                    </span>
                  </template>
                  <div style="max-width: 640px">
                    {{ item.metaJSON.question || '' }}
                  </div>
                </TTTooltip>
              </div>
              <div>
                <p class="tt-black--text text--lighten-2">
                  {{ itemToPath(item) }}
                </p>
              </div>
            </RouterLink>
          </template>
          <template #item.last_updated=" { item } ">
            <p class="d-flex">
              {{ item.last_updated ? formatDate(item.last_updated) : 'Не задано' }}
            </p>
          </template>
        </TTDataTable>
      </VCol>
    </VRow>
    <VRow v-show="!currentLocationId">
      <VCol cols="12">
        <h2 class="mb-3">
          Не задана локация
        </h2>
        <p class="mb-0">
          Выберите локацию для получения данных.
        </p>
      </VCol>
    </VRow>
    <VRow v-if="!contentAnalyticsList.length">
      <VCol cols="12">
        <h2 class="mb-3">
          Пока у вас нет аналитики по вопросам
        </h2>
        <p class="mb-0">
          Аналитика собирается из ответов сотрудников в приложении.
        </p>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { pageLocationToken } from '@/services';
import { contentTypeText } from '@/constants';
import { formatDate } from '@/utils';
import TextTemplate from '@/components/shared/TextTemplate.vue';

export default {
  name: 'ReportQuestions',
  components: { TextTemplate },
  inject: ['Names'],
  data() {
    return {
      contentAnalyticsList: [],
      locations: [],
      currentLocationId: 0,
      contentTypeText,
      headers: [
        {
          text: 'Элемент',
          value: 'element',
          sortable: false,
        },
        {
          text: 'Кол-во ответов',
          value: 'total_answers',
          sortable: false,
          width: 170,
        },
        {
          text: 'Последний ответ',
          value: 'last_updated',
          sortable: false,
          width: 220,
        },
      ],

    };
  },
  created() {
    this.$repositories.location.list({})
      .then((r) => {
        const { data } = r.data;
        this.locations = data;
        // делаем массив id локаций
        const locationIds = this.locations.map((item) => item.id);
        let loc;
        // проверяем, правильные ли данные нам пришли: сравниваем с locationIds и есть ли локация с таким id
        if (this.$route.params.id !== undefined
          && locationIds.indexOf(parseInt(this.$route.params.id, 10)) !== -1) {
          // в loc записывает id локации из урла
          loc = this.$route.params.id;
          pageLocationToken.set(loc);
        } else {
          // если пришел не верный id те локации с таким id нет, то достаем из локалстораджа id
          loc = pageLocationToken.get();
        }

        if (loc) {
          this.currentLocationId = parseInt(loc, 10);
          this.getData();
        } else {
          this.$refs.locationDropDown.$children[0].isMenuActive = true;
        }
      })
      .catch((e) => console.warn(e));
  },
  methods: {
    formatDate,
    metaToName(meta) {
      try {
        const metaJSON = JSON.parse(meta);
        return metaJSON.question || '';
      } catch (e) {
        console.warn(e);
        return '';
      }
    },
    itemToPath(item) {
      const path = [
        item.level.name,
        item.task.name,
        contentTypeText[item.type],
      ].join(', ');
      return path;
    },
    prepareData(item, name) {
      const resArr = [];
      const {
        content_type: contentType,
        result,
      } = item;
      resArr.push(contentType);
      resArr.push(name);
      let res;
      switch (contentType) {
        case 'RATE':
          res = result.rate;
          resArr.push(res.value);
          resArr.push(res.is_anonymous ? 'Анонимный' : 'Не анонимный');
          resArr.push(`Комментарий: ${res.comment}`);
          break;
        case 'YES_NO':
          res = result.yes_no;
          resArr.push(res.value ? 'ДА' : 'НЕТ');
          break;
        case 'SELECT_ONE':
          res = result.select_one;
          resArr.push(res.value);
          break;
        case 'SELECT_MANY':
          res = result.select_many;
          resArr.push(res.values.join(';'));
          break;
        case 'VOTE':
          res = result.vote;
          resArr.push(res.values.join(';'));
          break;
        case 'ENTER_STRING':
          res = result.enter_string;
          resArr.push(res.value);
          break;
        default:
          resArr.push('Неизвестный тип');
      }
      return resArr.join(',');
    },
    // TODO DELETE Если нигде не используется
    async downloadAnalytics(id) {
      try {
        const content = this.contentAnalyticsList.find((item) => item.id === id);
        const name = this.metaToName(content.meta);
        const res = await this.$repositories.report.getTaskContentReportDetail({ data: { id } });
        const { data } = res.data;
        let csvContent = 'data:text/csv;charset=utf-8,';

        data.forEach((item, i) => {
          csvContent += `${i + 1},${this.prepareData(item, name)}\r\n`;
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `${name}.csv`);
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file.
        document.body.removeChild(link);
      } catch (e) {
        console.warn(e);
      }
    },
    handleLocation(item) {
      pageLocationToken.set(item.id);
      this.currentLocationId = item.id;
      this.getData();
    },
    getData() {
      this.contentAnalyticsList = [];
      this.$repositories.report
        .getTaskContentReportList({ data: { location_id: this.currentLocationId } })
        .then((r) => {
          if (r && r.data) {
            const { data } = r.data;
            this.contentAnalyticsList = data.map((item) => {
              const newItem = { ...item };
              try {
                newItem.metaJSON = JSON.parse(newItem.meta);
              } catch (e) {
                console.warn(e);
                newItem.metaJSON = {};
              }
              return newItem;
            });
          }
        })
        .catch((e) => {
          console.warn(e);
        });
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
