var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{attrs:{"fluid":""}},[_c('VRow',[_c('VCol',[_c('h1',{staticClass:"tt-text-headline-1"},[_vm._v(" Аналитика по вопросам ")])])],1),_c('VRow',{staticClass:"justify-space-between align-center mb-3"},[_c('VCol',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('TTSelect',{ref:"locationDropDown",staticClass:"tt-select-attach",attrs:{"value":_vm.currentLocationId,"items":_vm.locations,"item-text":"name","item-value":"id","return-object":"","large":"","label":"Локация","menu-props":{ auto : true },"attach":"","data-test":"select-locations"},on:{"change":_vm.handleLocation},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var disabled = ref.disabled;
return [_c('TextTemplate',{staticClass:"v-select__selection v-select__selection--comma",attrs:{"disabled":disabled,"text":item.name,"show-prefix":!item.is_active && !!item.id}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('TextTemplate',{attrs:{"text":item.name,"show-prefix":!item.is_active && !!item.id}})]}}])})],1)],1),_c('VRow',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentLocationId && _vm.contentAnalyticsList.length),expression:"currentLocationId && contentAnalyticsList.length"}]},[_c('VCol',[_c('TTDataTable',{attrs:{"items":_vm.contentAnalyticsList,"headers":_vm.headers,"extra-tall":"","hide-default-footer":"","server-items-length":_vm.contentAnalyticsList.length,"data-test":"location-table"},scopedSlots:_vm._u([{key:"item.element",fn:function( ref ){
var item = ref.item;
return [_c('RouterLink',{staticClass:"text-decoration-none cursor--pointer",attrs:{"tag":"div","to":{ name : _vm.Names.R_REPORT_CONTENT_DETAIL, params : { id : item.id } }}},[_c('div',{staticClass:"ellipsis-four-lines"},[_c('TTTooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.metaJSON.question || '')+" ")])]}}],null,true)},[_c('div',{staticStyle:{"max-width":"640px"}},[_vm._v(" "+_vm._s(item.metaJSON.question || '')+" ")])])],1),_c('div',[_c('p',{staticClass:"tt-black--text text--lighten-2"},[_vm._v(" "+_vm._s(_vm.itemToPath(item))+" ")])])])]}},{key:"item.last_updated",fn:function( ref ){
var item = ref.item;
return [_c('p',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(item.last_updated ? _vm.formatDate(item.last_updated) : 'Не задано')+" ")])]}}])})],1)],1),_c('VRow',{directives:[{name:"show",rawName:"v-show",value:(!_vm.currentLocationId),expression:"!currentLocationId"}]},[_c('VCol',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-3"},[_vm._v(" Не задана локация ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Выберите локацию для получения данных. ")])])],1),(!_vm.contentAnalyticsList.length)?_c('VRow',[_c('VCol',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-3"},[_vm._v(" Пока у вас нет аналитики по вопросам ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Аналитика собирается из ответов сотрудников в приложении. ")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }